import * as React from "react";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/header-one";
import Footer from "@layout/footer/footer-one";
import Cta from "@containers/global/cta-area/section-one";
import SectionOne from '@containers/industrial-research/section-one'
import Banner from "@components/banners/Ai";

const IndustrialResearchPage = ({ pageContext, location }) => {
	return (
		<Layout location={location}>
			<Seo title="Tabs" />
			<Header />
			<Banner
				pageContext={pageContext}
				location={location}
				title="Industrial Research & Development"
			/>
			<main className="site-wrapper-reveal">
				<SectionOne />
				<Cta />
			</main>
			<Footer />
		</Layout>
	)
}

export default IndustrialResearchPage
